import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';
import parseResultsUrl from "../../../../utils/property-results/parseResultsUrl";
import generateFiltersfromPageData from "../../../../utils/property-results/generateFiltersfromPageData";
import createResultsUrl from "../../../../utils/property-results/createResultsUrl";
import SearchResultPage from "../../../../templates/search-results-auction-template";
import {Past_Auctions_Url} from "@Components/common/site/constants"

const SearchResultAuctionSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    const [free_text_search, setFreeTextSearch] = useState([]);
    const [h1_areas, setH1Areas] = useState([]);


    const [algoliadata, getAlgoliadata] = useState([]);
    // const [mysortbyoption, setMysortbyoption] = useState('');
    // const [mypageoption, setMypageoption] = useState('');

    //the current page data are lives here
    const page_url_data = parseResultsUrl(props.location)


    // you can make default search page results filters here
    const myalgoliafilters = generateFiltersfromPageData(free_text_search, page_url_data['departmentVal'], page_url_data['searchtypeVal'], page_url_data['statusVal'], page_url_data['statusenableDefaultVal'], page_url_data['areaVal'], page_url_data['bedVal'], page_url_data['minpriceVal'], page_url_data['maxpriceVal'], page_url_data['buildingval'], page_url_data['energyVal'], page_url_data['agreedVal'], page_url_data['mustincludeVal'])

    // navigate to pages based on filters


    useEffect(() => {
        getAlgoliaResutls(myalgoliafilters);
    }, [props.location]);

    const getAlgoliaResutls = (myalgoliafilters) => {
        // lets run algolia search query to fetch hits, stats and number of pages
        const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
        const index = client.initIndex(page_url_data['sortVal'] ? page_url_data['sortVal'] : process.env.GATSBY_ALGOLIA_INDEX_NAME);
        //const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);

        
        var area_name_length = page_url_data['areaVal'].length
        var area_name = area_name_length == 1 ? page_url_data['areaVal'].toString() : ''
        if(free_text_search.length > 0) {
            area_name = area_name
        }
        else {
            area_name = area_name
        }

        index.search((area_name_length == 1 && area_name != true && area_name != 'ireland' ? area_name : ''), {
            // similarQuery: area_name_length == 1 && area_name != true && area_name != 'ireland' ? area_name : '',
            filters: myalgoliafilters,
            page: (page_url_data['pageVal'] - 1),
            hitsPerPage: (page_url_data['layoutVal'] ? 1000 : 21)
        }).then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
            var myArray = { "total": nbHits, "current_page_number": page, "hitsPerPage": hitsPerPage, "number_of_pages": nbPages, "hits": hits };
            getAlgoliadata(myArray)
        });
    }

    return (
        <React.Fragment>
            <SearchResultPage 
                {...algoliadata}
                locationname="/services/property-auctions/upcoming-auctions/" 
                pcategorytype="auction_residential" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype={"For Sale"}
                tag="auction"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                setFreeTextSearch={setFreeTextSearch}
                free_text_search={free_text_search}
                page_url_data = {page_url_data}
                h1_areas={h1_areas}
                setH1Areas={setH1Areas}
                {...props}
                Alias={"upcoming-auctions"}
                Pagename={"Upcoming Auctions"}
            />
        </React.Fragment>
    );
};

export default SearchResultAuctionSales;
